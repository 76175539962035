import { render, staticRenderFns } from "./Spot.vue?vue&type=template&id=1f50975e&scoped=true&"
import script from "./Spot.vue?vue&type=script&lang=ts&"
export * from "./Spot.vue?vue&type=script&lang=ts&"
import style0 from "./Spot.vue?vue&type=style&index=0&id=1f50975e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f50975e",
  null
  
)

export default component.exports